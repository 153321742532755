<script>
import ManagesClientSession from '@/mixins/ManagesClientSession';
import ManagesLocaleCookies from '@/mixins/ManagesLocaleCookies';

export default {
  name: 'VetsterFooterLocales',

  mixins: [
    ManagesClientSession,
    ManagesLocaleCookies,
  ],

  layout: 'default',
};
</script>
