import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTiktok,
  faTwitter,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faBadgerHoney,
  faBandAid,
  faBellOn,
  faCartPlus,
  faClipboardPrescription,
  faCow,
  faCrow,
  faAddressBook as fadAddressBook,
  faClock as fadClock,
  faGlobeAmericas as fadGlobeAmericas,
  faHandsHelping as fadHandsHelping,
  faDog,
  faPaw as fadPaw,
  faPills as fadPills,
  faQuoteLeft as fadQuoteLeft,
  faSearch as fadSearch,
  faUserClock as fadUserClock,
  faUserMd as fadUserMd,
  faFish,
  faFrog,
  faHandHoldingMedical,
  faHeart,
  faHorse,
  faCat as falCat,
  faLifeRing,
  faMapMarkedAlt,
  faMonkey,
  faNarwhal,
  faPhoneLaptop,
  faPoop,
  faRabbit,
  faSheep,
  faShippingFast,
  faSirenOn,
  faSnake,
  faStar,
  faTurtle,
  faUnicorn,
  faVirus,
  faWeight,
  faWhistle,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faAddressBook,
  faAlarmClock,
  faAnalytics,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowRotateLeft,
  faArrowUp,
  faArrowUpRightFromSquare,
  faArrowUpToLine,
  faAward,
  faBalanceScaleRight,
  faBan,
  faBandage,
  faBars,
  faBird,
  faBook,
  faBooks,
  faBriefcaseMedical,
  faCalendar,
  faCalendarCheck,
  faCalendarClock,
  faCalendarDay,
  faCalendarPlus,
  faCarSide,
  faCat,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircleNotch,
  faCircleUser,
  faClipboardListCheck,
  faClock,
  faCoins,
  faComment,
  faCommentLines,
  faComments,
  faCompass,
  faCompressArrowsAlt,
  faComputerClassic,
  faCreditCard,
  faCut,
  faDisease,
  faDogLeashed,
  faDollarSign,
  faDoorOpen,
  faEnvelope,
  faExclamationCircle,
  faExternalLinkAlt,
  faFaceHeadBandage,
  faFileCertificate,
  faFileInvoiceDollar,
  faFlaskVial,
  faFlower,
  faFolderMedical,
  faFolderPlus,
  faGem,
  faGiftCard,
  faGlass,
  faGlobe,
  faGlobeAmericas,
  faHandDots,
  faHandHoldingCircleDollar,
  faHandHoldingHeart,
  faHandHoldingSeedling,
  faHandHoldingUsd,
  faHandshake,
  faHandsHelping,
  faHandsHoldingHeart,
  faHandsUsd,
  faHelmetSafety,
  faHome,
  faHorseHead,
  faHouseHeart,
  faInfo,
  faInfoCircle,
  faLaptop,
  faLaptopHouse,
  faLaptopMedical,
  faLaptopMobile,
  faBadgerHoney as falBadgerHoney,
  faDog as falDog,
  faFish as falFish,
  faHeart as falHeart,
  faLightbulbDollar,
  faLightEmergency,
  faInfinity as falInfinity,
  faMonkey as falMonkey,
  faLocationDot,
  faLock,
  faLongArrowRight,
  faPoop as falPoop,
  faRabbit as falRabbit,
  faSheep as falSheep,
  faSirenOn as falSirenOn,
  faSnake as falSnake,
  faStar as falStar,
  faLungs,
  faVirus as falVirus,
  faWeight as falWeight,
  faWhistle as falWhistle,
  faMessage,
  faMinus,
  faMobile,
  faMobileNotch,
  faNewspaper,
  faNotesMedical,
  faPaintBrushFine,
  faPaw,
  faPhone,
  faPiggyBank,
  faPills,
  faPlay,
  faPlus,
  faPrescription,
  faPrescriptionBottle,
  faPrescriptionBottleAlt,
  faQuestion,
  faQuestionCircle,
  faQuoteLeft,
  faRocket,
  faRocketLaunch,
  faScroll,
  faSearch,
  faShieldCheck,
  faShop,
  faSignsPost,
  faSmile,
  faSmileBeam,
  faSpa,
  faSparkles,
  faSquarePollVertical,
  faSquareQuestion,
  faStars,
  faStethoscope,
  faSyringe,
  faTag,
  faTasks,
  faTimes,
  faTooth,
  faTree,
  faTruckFast,
  faTurkey,
  faUserChart,
  faUserCircle,
  faUserClock,
  faUserDoctor,
  faUserDoctorMessage,
  faUserMd,
  faUserNurse,
  faUserPlus,
  faUsers,
  faVideo,
  faWebcam,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAngleDown as fasAngleDown,
  faAngleRight as fasAngleRight,
  faArrowRightLong as fasArrowRightLong,
  faArrowUpToLine as fasArrowUpToLine,
  faBadgeCheck as fasBadgeCheck,
  faBone as fasBone,
  faBooks as fasBooks,
  faCircle as fasCircle,
  faFolderMedical as fasFolderMedical,
  faFolderPlus as fasFolderPlus,
  faShoppingCart,
  faLocationDot as fasLocationDot,
  faMessages as fasMessages,
  faNewspaper as fasNewsPaper,
  faPaw as fasPaw,
  faPlus as fasPlus,
  faPrescription as fasPrescription,
  faPrescriptionBottleAlt as fasPrescriptionBottleAlt,
  faSignsPost as fasSignsPost,
  faSquarePollVertical as fasSquarePollVertical,
  faStar as fasStar,
  faTimes as fasTimes,
  faUser as fasUser,
  faVideo as fasVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import '@/mixins/PetConcerns';
import '@/mixins/PostSideBarIcons';

export default function registerFontAwesome() {
  // eslint-disable-next-line vue/multi-word-component-names
  Vue.component('Fa', FontAwesomeIcon);

  library.add({
    faAddressBook,
    faAlarmClock,
    faAnalytics,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowLeft,
    faArrowRight,
    faArrowRotateLeft,
    faArrowUp,
    faArrowUpRightFromSquare,
    faArrowUpToLine,
    faAward,
    faBadgerHoney,
    faBalanceScaleRight,
    faBan,
    faBandAid,
    faBandage,
    faBars,
    faBellOn,
    faBird,
    faBook,
    faBooks,
    faBriefcaseMedical,
    faCalendar,
    faCalendarCheck,
    faCalendarClock,
    faCalendarDay,
    faCalendarPlus,
    faCartPlus,
    faCarSide,
    faCat,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCircle1,
    faCircle2,
    faCircle3,
    faCircleNotch,
    faCircleUser,
    faClipboardListCheck,
    faClipboardPrescription,
    faClock,
    faCoins,
    faComment,
    faCommentLines,
    faComments,
    faCompass,
    faCompressArrowsAlt,
    faComputerClassic,
    faCow,
    faCreditCard,
    faCrow,
    faCut,
    faDisease,
    faDog,
    faDogLeashed,
    faDollarSign,
    faDoorOpen,
    faEnvelope,
    faExclamationCircle,
    faExternalLinkAlt,
    faFacebook,
    faFaceHeadBandage,
    faFileCertificate,
    faFileInvoiceDollar,
    faFish,
    faFlaskVial,
    faFlower,
    faFolderMedical,
    faFolderPlus,
    faFrog,
    faGem,
    faGiftCard,
    faGlass,
    faGlobe,
    faGlobeAmericas,
    faHandDots,
    faHandHoldingCircleDollar,
    faHandHoldingHeart,
    faHandHoldingMedical,
    faHandHoldingSeedling,
    faHandHoldingUsd,
    faHandsHelping,
    faHandsHoldingHeart,
    faHandsUsd,
    faHandshake,
    faHeart,
    faHelmetSafety,
    faHome,
    faHorse,
    faHorseHead,
    faHouseHeart,
    faInfo,
    faInfoCircle,
    faInstagram,
    faLaptop,
    faLaptopHouse,
    faLaptopMedical,
    faLaptopMobile,
    faLifeRing,
    faLightbulbDollar,
    faLightEmergency,
    faLinkedin,
    faLocationDot,
    faLock,
    faLongArrowRight,
    faLungs,
    faMapMarkedAlt,
    faMessage,
    faMinus,
    faMobile,
    faMobileNotch,
    faMonkey,
    faNarwhal,
    faNewspaper,
    faNotesMedical,
    faPaintBrushFine,
    faPaw,
    faPhone,
    faPhoneLaptop,
    faPiggyBank,
    faPills,
    faPinterest,
    faPlay,
    faPlus,
    faPoop,
    faPrescription,
    faPrescriptionBottle,
    faPrescriptionBottleAlt,
    faQuestion,
    faQuestionCircle,
    faQuoteLeft,
    faRabbit,
    faRocket,
    faRocketLaunch,
    faScroll,
    faSearch,
    faSheep,
    faShieldCheck,
    faShippingFast,
    faShop,
    faShoppingCart,
    faSignsPost,
    faSirenOn,
    faSmile,
    faSmileBeam,
    faSnake,
    faSpa,
    faSparkles,
    faSquarePollVertical,
    faSquareQuestion,
    faStar,
    faStars,
    faStethoscope,
    faSyringe,
    faTag,
    faTasks,
    faTiktok,
    faTimes,
    faTooth,
    faTree,
    faTruckFast,
    faTurkey,
    faTurtle,
    faTwitter,
    faUnicorn,
    faUserChart,
    faUserCircle,
    faUserClock,
    faUserDoctor,
    faUserDoctorMessage,
    faUserMd,
    faUserNurse,
    faUserPlus,
    faUsers,
    faVideo,
    faVirus,
    faWebcam,
    faWeight,
    faWhistle,
    faXTwitter,
    faYoutube,
    fadAddressBook,
    fadClock,
    fadGlobeAmericas,
    fadHandsHelping,
    fadPaw,
    fadPills,
    fadQuoteLeft,
    fadSearch,
    fadUserClock,
    fadUserMd,
    falBadgerHoney,
    falCat,
    falDog,
    falFish,
    falHeart,
    falInfinity,
    falMonkey,
    falPoop,
    falRabbit,
    falSheep,
    falSirenOn,
    falSnake,
    falStar,
    falVirus,
    falWeight,
    falWhistle,
    fasAngleDown,
    fasAngleRight,
    fasArrowRightLong,
    fasArrowUpToLine,
    fasBadgeCheck,
    fasBone,
    fasBooks,
    fasCircle,
    fasFolderMedical,
    fasFolderPlus,
    fasLocationDot,
    fasMessages,
    fasNewsPaper,
    fasPaw,
    fasPlus,
    fasPrescription,
    fasPrescriptionBottleAlt,
    fasSignsPost,
    fasSquarePollVertical,
    fasStar,
    fasTimes,
    fasUser,
    fasVideo,
  });
}
