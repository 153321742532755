export default {
  methods: {
    scrollTo(id, offset) {
      this.$nextTick(() => {
        const el = document.getElementById(id);

        if (el) {
          window.scrollTo({
            top: el.offsetTop - (offset || 63), // sticky navbar height
            behavior: 'smooth',
          });
        }
      });
    },
  },
};
