<template>
  <div :class="{ 'animated shake fast': shake }">
    <div class="flex justify-start">
      <label
        v-if="label"
        :for="getId"
        :class="labelClass"
        class="block font-semibold text-sm leading-5"
      >
        {{ label }}
      </label>
    </div>
    <slot />
    <p
      v-if="feedback"
      class="mt-1 font-normal text-xs leading-4"
      :class="feedbackClass"
    >
      {{ feedback }}
    </p>
    <p
      v-else-if="descriptionLabel"
      class="mt-1 font-normal text-xs leading-4"
      :class="disabled ? 'text-neutral-600/40' : 'text-neutral-600'"
    >
      {{ descriptionLabel }}
    </p>
  </div>
</template>

<script>
import { uuid } from '@/helpers/uuid';

// TODO: Add the rest of the variants
export default {
  name: 'VetsterInputGroup',

  props: {
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    descriptionLabel: {
      type: String,
      default: null,
    },
    feedback: {
      type: [String, Boolean],
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    shake: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getId() {
      return this.id || uuid();
    },
    labelClass() {
      if (this.error) {
        return this.disabled ? 'text-red-600/40' : 'text-red-600';
      }
      return this.disabled ? 'text-neutral-900/40' : 'text-neutral-900';
    },
    feedbackClass() {
      return this.error ? 'text-red-600' : '';
    },
  },
};
</script>
