import { library } from '@fortawesome/fontawesome-svg-core';
import { faTreeChristmas } from '@fortawesome/pro-duotone-svg-icons';
import {
  faBells as falBells,
  faBird as falBird,
  faBookmark as falBookmark,
  faCat as falCat,
  faDog as falDog,
  faExclamationTriangle as falExclamationTriangle,
  faEye as falEye,
  faFileLines as falFileLines,
  faFileMedical as falFileMedical,
  faFileSearch as falFileSearch,
  faGift as falGift,
  faHorse as falHorse,
  faInfoCircle as falInfoCircle,
  faKey as falKey,
  faLongArrowRight as falLongArrowRight,
  faRoute as falRoute,
  faSearch as falSearch,
  faShield as falShield,
  faSirenOn as falSirenOn,
  faSparkles as falSparkles,
  faStethoscope as falStethoscope,
  faTurtle as falTurtle,
  faUserMd as falUserMd,
  faUsers as falUsers,
  faVideo as falVideo,
  faShareAlt,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBells,
  faBird,
  faCat,
  faDog,
  faExclamationTriangle,
  faFileMedical,
  faFileSearch,
  faGift,
  faHorse,
  faInfoCircle,
  faKey,
  faRoute,
  faSearch,
  faShield,
  faSirenOn,
  faSparkles,
  faStethoscope,
  faTurtle,
  faUserMd,
  faUsers,
  faVideo,
  faVolumeSlash,
} from '@fortawesome/pro-solid-svg-icons';

library.add({
  faBells,
  faBird,
  faCat,
  faDog,
  faExclamationTriangle,
  faFileMedical,
  faFileSearch,
  faGift,
  faHorse,
  faInfoCircle,
  faKey,
  faRoute,
  faSearch,
  faShareAlt,
  faShield,
  faSirenOn,
  faSparkles,
  faStethoscope,
  faTreeChristmas,
  faTurtle,
  faUserMd,
  faUsers,
  faVideo,
  faVolumeSlash,
  falBells,
  falBird,
  falBookmark,
  falCat,
  falDog,
  falExclamationTriangle,
  falEye,
  falFileLines,
  falFileMedical,
  falFileSearch,
  falGift,
  falHorse,
  falInfoCircle,
  falKey,
  falLongArrowRight,
  falRoute,
  falSearch,
  falShield,
  falSirenOn,
  falSparkles,
  falStethoscope,
  falTurtle,
  falUserMd,
  falUsers,
  falVideo,
});
